// Custom file upload component to handle redux form 'file' type issue

import React from 'react';

const ModelUpload = (props) => {
  const { input } = props;
  const onInputChange = (e) => {
    e.preventDefault();
    const filepath = [e.target.files][0];
    input.onChange(filepath);
    // extract file name and display
    if (document.getElementById('modelfile').files.length === 0) {
      document.getElementById("predict-textbox").value += 'No model files selected\n';
    }
    // file number verification
    if (document.getElementById('modelfile').files.length > 4) {
      document.getElementById("predict-textbox").value += 'Too many files selected, there are a maximum of 4 configuration files!\n';
      document.getElementById('modelfile').value = [];
    }
    // display file names in textarea
    for (let i = 0; i < document.getElementById('modelfile').files.length; i += 1) {
      const modelfile = document.getElementById('modelfile').files[i].name;
      document.getElementById("predict-textbox").value += `Model config files: ${modelfile}\n`;
    }
  };


  return (
    <div>
      <input
        className="inputfile"
        id="modelfile"
        type="file"
        multiple
        onChange={onInputChange}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
        required
      />
      <label id="model-label" htmlFor="modelfile">Model</label>

    </div>

  );
};

export default ModelUpload;
