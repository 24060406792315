import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { modelFileUpload } from '../actions';
import ModelUpload from '../components/model_upload';


class ModelUploadForm extends Component {
  onSubmit = (values) => {
    if (document.getElementById('modelfile').files.length === 0) {
      document.getElementById('predict-textbox').value += 'ERROR: No files selected for upload!\n';
    } else {
      this.props.modelFileUpload(values);
    }
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <div id="modelupload-wrapper" data-step="10" data-intro="Choose model files to upload and restore ('model' option only) and hit the upload button.">

          <Field
            name="modelfiles"
            component={ModelUpload}
          />
          <button className="btn btn-primary" id="modelsubmit-button" type="submit" disabled={this.props.pristine || this.props.submitting}>
            <i className="fa fa-upload" />
          </button>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    model: state.model
  };
}

export default reduxForm({ form: 'uploadForm' })(
  connect(mapStateToProps, { modelFileUpload })(ModelUploadForm)
);
