import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { anaylseTweet } from '../actions';


class TweetNew extends Component {
  // display red dot and clear textarea on mounting
  componentDidMount() {
    document.getElementById("tweet-status").className = "red-dot";
    document.getElementById("tweet-textbox").value = "";
  }

  // disable submit button and show spinner after form submitted
  onSubmit = (formdata) => {
    document.getElementById("submit-tweet").disabled = true;
    document.getElementById("loading-tweet").className = "spinner-border spinner-border-sm";
    this.props.anaylseTweet(formdata);
  }

  handleChange = () => {
    document.getElementById("tweet-textbox").value = "";
    document.getElementById("submit-tweet").disabled = false;
    document.getElementById("tweet-status").className = "red-dot";
  }


  render () {
    return (
      <div className="content-wrapper">
        <div className="Tweet-wrapper">
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <div>
              <Field
                className="form-control"
                id="query"
                label="Query"
                name="query"
                placeholder="&#x1F50D;Search"
                type="text"
                component="input"
                onChange={this.handleChange}
                onFocus={(e) => e.preventDefault()}
                onBlur={(e) => e.preventDefault()}
                spellCheck="false"
                autoComplete="on"
                required
              />
            </div>
            <div className="tweet-select">
              <label><strong>Tweets</strong></label>
              <Field name="count" component="select" required>
                <option />
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </Field>
            </div>

            <button className="btn btn-primary btn-block" id="submit-tweet" type="submit" disabled={this.props.pristine || this.props.submitting}>
              <span id="loading-tweet" />
                &nbsp;Anaylse Tweets
            </button>
          </form>

          <textarea id="tweet-textbox" rows="14" spellCheck="false" readOnly />
          <div id="tweet-footer">
            <div id="tweet-status" />
            <p id="resultString">{this.props.tweet}</p>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tweet: state.tweet
  };
}

export default reduxForm({ form: 'newTweetForm' })(
  connect(mapStateToProps, { anaylseTweet })(TweetNew)
);
