import { NEW_PREDICTION } from '../actions';

export default function predictionReducer(state = [], action) {
  switch (action.type) {
    case NEW_PREDICTION: {
      return (action.payload);
    }
    default: {
      return state;
    }
  }
}
