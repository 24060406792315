// Custom file upload component to handle redux form 'file' type issue

import React from 'react';

const FileUpload = (props) => {
  const { input } = props;
  const onInputChange = (e) => {
    e.preventDefault();
    const filepath = [e.target.files][0];
    input.onChange(filepath);
    // extract file name and display
    if (document.getElementById('file').files.length === 0) {
      document.getElementById("predict-textbox").value += 'No file selected\n';
    }
    const myfile = document.getElementById('file').files[0].name;
    document.getElementById("predict-textbox").value += `CSV data upload file: ${myfile}\n`;
  };


  return (
    <input
      className="inputfile"
      id="file"
      type="file"
      accept=".csv"
      multiple={false}
      onChange={onInputChange}
      onFocus={(e) => e.preventDefault()}
      onBlur={(e) => e.preventDefault()}
      required
    />
  );
};

export default FileUpload;
