import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { predictValue, downloadModel } from '../actions';
import FileUpload from '../components/file_upload';
import ModelUploadForm from './modelUpload_form';


class PredictNew extends Component {
  // clear textarea and display red dot on mounting
  componentDidMount() {
    document.getElementById('modelfile').disabled = true;
    document.getElementById("file-status").className = "red-dot";
    document.getElementById("predict-textbox").value = "";
  }


  componentWillUnmount() {
    // simulate kill button click to force stop process on react route change
    document.getElementById("kill-button").click();
    console.log("Disconnected on unmount");
  }


  onSubmit = (data) => {
    this.props.predictValue(data);
    // diasable button and show spinner when form submitted
    document.getElementById('submit-button').disabled = true;
    document.getElementById("loading-file").className = "spinner-border spinner-border-sm";
    document.getElementById("file-status").className = "green-dot";
  }


  // handles checkbox to enable model upload button
  handleChange = () => {
    if (document.getElementById('use_model').checked === true) {
      document.getElementById('modelfile').disabled = false;
      document.getElementById('epochs').disabled = true;
      document.getElementById('batch').disabled = true;
      document.getElementById('learning_rate').disabled = true;
      console.log('use model');
    } else {
      document.getElementById('modelfile').disabled = true;
      document.getElementById('epochs').disabled = false;
      document.getElementById('batch').disabled = false;
      document.getElementById('learning_rate').disabled = false;
      console.log('optimise');
    }
  }


  intro = () => {
    introJs().setOption('showProgress', true).start();
  }


  downloadModelFiles = () => {
    this.props.downloadModel();
  }


  resetForm = () => {
  // reset form field values and clear selected file name display
    this.props.reset('newPostForm');
    // clear file upload, form fields, textarea, spinner
    document.getElementById('file').value = "";
    document.getElementById('modelfile').value = "";
    document.getElementById('modelfile').disabled = true;
    document.getElementById("predict-textbox").value = "";
    document.getElementById("loading-file").className = "";
    document.getElementById("file-status").className = "red-dot";
    document.getElementById('batch').disabled = false;
    document.getElementById('epochs').disabled = false;
    document.getElementById('learning_rate').disabled = false;
  }


  render() {
    return (
      <div className="content-wrapper">
        <div className="dashboard-wrapper">
          <form id="predict-form" onSubmit={this.props.handleSubmit(this.onSubmit)}>

            <div id="label1">
              <label className="label-margin" data-step="2" data-intro="Select number of time steps to train model over."><strong>Days</strong></label>
              <Field name="rolling_window" id="days-window" component="select" onFocus={(e) => e.preventDefault()} onBlur={(e) => e.preventDefault()} required>
                <option />
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
                <option value="90">90</option>
              </Field>
            </div>

            <div id="label2">
              <label className="label-margin" data-step="3" data-intro="Select the number of hidden layers in your neural network."><strong>Layers</strong></label>
              <Field name="layers" id="layers" component="select" onFocus={(e) => e.preventDefault()} onBlur={(e) => e.preventDefault()} required>
                <option />
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </Field>
            </div>

            <div id="label3">
              <label className="label-margin" data-step="4" data-intro="Select the number of neurons per layer."><strong>Neurons</strong></label>
              <Field name="neurons" id="neurons" component="select" onFocus={(e) => e.preventDefault()} onBlur={(e) => e.preventDefault()} required>
                <option />
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
              </Field>
            </div>

            <div id="label4">
              <label className="label-margin" data-step="5" data-intro="Choose the learning rate ('optimise' model only)."><strong>LR</strong></label>
              <Field name="learning_rate" id="learning_rate" component="select" onFocus={(e) => e.preventDefault()} onBlur={(e) => e.preventDefault()} required>
                <option />
                <option value="0.1">0.1</option>
                <option value="0.01">0.01</option>
                <option value="0.001">0.001</option>
                <option value="0.0001">0.0001</option>
                <option value="0.00001">0.00001</option>
              </Field>
            </div>

            <div id="label5">
              <label className="label-margin" data-step="6" data-intro="Choose model type -  Recurrent Neural Network (feedforward), Long Short-Term Memory (feedback), or Gated Recurrent Unit."><strong>Model</strong></label>
              <Field
                name="model"
                id="model_rnn"
                component="input"
                type="radio"
                value="model_rnn"
                onFocus={(e) => e.preventDefault()}
                onBlur={(e) => e.preventDefault()}
                required
              />
              <label htmlFor="model_rnn">rnn</label>
            </div>

            <div id="label6">
              <Field
                name="model"
                id="model_lstm"
                component="input"
                type="radio"
                value="model_lstm"
                onFocus={(e) => e.preventDefault()}
                onBlur={(e) => e.preventDefault()}
                required
              />
              <label htmlFor="model_lstm">lstm</label>
            </div>

            {/*
            // omitted code for fourth model type//
            <Field
              name="model"
              id="model_lstm_p"
              component="input"
              type="radio"
              value="model_lstm_p"
              onFocus={(e) => e.preventDefault()}
              onBlur={(e) => e.preventDefault()}
              required
            />
            <label htmlFor="model_lstm_p">lstm (peephole)</label>
            */}

            <div id="label7">
              <Field
                name="model"
                id="model_gru"
                component="input"
                type="radio"
                value="model_gru"
                onFocus={(e) => e.preventDefault()}
                onBlur={(e) => e.preventDefault()}
                required
              />
              <label htmlFor="model_gru">gru</label>
            </div>

            <div id="label8">
              <label className="label-margin" data-step="7" data-intro="Choose the number of data batches to run ('optimise' model only)."><strong>Batch</strong></label>
              <Field name="batch" id="batch" component="select" onFocus={(e) => e.preventDefault()} onBlur={(e) => e.preventDefault()} required>
                <option />
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
              </Field>
            </div>

            <div id="label9">
              <label className="label-margin" data-step="8" data-intro="Choose the number of epochs to run ('optimise' model only)."><strong>Epochs</strong></label>
              <Field name="epochs" id="epochs" component="select" onFocus={(e) => e.preventDefault()} onBlur={(e) => e.preventDefault()} required>
                <option />
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
              </Field>
            </div>

            {/* Custom {fileUpload} to handle reddux form 'type: file' issue */}
            <div id="upload-wrapper">

              <Field
                name="use_model"
                id="optimise"
                component="input"
                type="radio"
                value="false"
                onChange={this.handleChange}
                onFocus={(e) => e.preventDefault()}
                onBlur={(e) => e.preventDefault()}
                required
              />
              <label htmlFor="optimise" data-step="1" data-intro="Choose to train a new model or restore a previous model."><strong>Optimise</strong></label>

              <Field
                name="use_model"
                id="use_model"
                component="input"
                type="radio"
                value="true"
                onChange={this.handleChange}
                onFocus={(e) => e.preventDefault()}
                onBlur={(e) => e.preventDefault()}
                required
              />
              <label htmlFor="use_model"><strong>Use model</strong></label>


              <Field
                name="file"
                component={FileUpload}
              />
              <label id="csv-label" htmlFor="file" data-step="9" data-intro="Upload a CSV file of open, high, low, close data">Upload CSV</label>
              
              {/* event listener for kill button in action creator index.js line 116 */}
              <button className="btn btn-danger" id="kill-button" type="button" data-step="14" data-intro="Stop the process at anytime! (maybe you notice that the model results are not converging to a minimum efficiently).">
                Stop Process!
              </button>

            </div>
            <div id="button-wrapper">
              <button className="btn btn-primary" id="submit-button" type="submit" disabled={this.props.pristine || this.props.submitting} data-step="11" data-intro="Hit 'Run' to start the model.  When restoring models ('model' option), ensure that parameters for days, layers, neurons, and model exactly match those of the orginal model!">
                <span id="loading-file" />
                  &nbsp;Run
              </button>

              <span title="Click for help!" onClick={this.intro}>
                <i className="fa fa-question-circle fa-lg" />
              </span>

              <button className="btn btn-light" id="cancel-button" type="button" onClick={this.resetForm} data-step="15" data-intro="Reset the form inputs.">
                Reset
              </button>
            </div>
          </form>

          <textarea id="predict-textbox" rows="12" spellCheck="false" readOnly data-step="12" data-intro="The results of the optimisation will be displayed here in real time." />

          <div id="download-footer">
            <div id="file-status" className={this.props.prediction} />
            <div id="file-footer">
              <ModelUploadForm />
              <button className="btn" id="download-button" type="button" onClick={this.downloadModelFiles} data-step="13" data-intro="When the model has finished training, the configuration files and chart can be downloaded.">
                <i className="fa fa-download" />
              </button>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    prediction: state.prediction,
    download: state.download
  };
}

export default reduxForm({ form: 'newPostForm' })(
  connect(mapStateToProps, { predictValue, downloadModel })(PredictNew)
);
