import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { scrapeData } from '../actions';


class CryptoData extends Component {
  componentDidMount() {
    // display red dot and clears status text on mount
    document.getElementById("download-status").innerHTML = "";
    document.getElementById("csv-status").className = "red-dot";
  }

  onSubmit = (formdata) => {
    document.getElementById("submit-crypto").disabled = true;
    document.getElementById("loading-crypto").className = "spinner-border spinner-border-sm";
    this.props.scrapeData(formdata);
  }

  handleSubmit = () => {
    document.getElementById("crypto-textbox").value = "";
    document.getElementById("csv-status").className = "red-dot";
    document.getElementById("download-status").innerHTML = "";
  }

  render () {
    return (
      <div className="content-wrapper">
        <div className="crypto-wrapper">
          <form id="crypto-form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <div className="crypto-header-wrapper">

              <div id="crypto-input">
                <label className="crypto-label"><strong>Choose</strong></label>
                <Field id="select-box" name="coin" className="form-control" onChange={this.handleChange} component="select" required>
                  <option value="">Coin</option>
                  <option value="bitcoin">Bitcoin</option>
                  <option value="ethereum">Ethereum</option>
                  <option value="eos">EOS</option>
                  <option value="ripple">Ripple</option>
                  <option value="stellar">Stellar</option>
                  <option value="litecoin">Litecoin</option>
                  <option value="tron">Tron</option>
                  <option value="cardano">Cardano</option>
                  <option value="bitcoin-cash">Bitcoin Cash</option>
                  <option value="binance-coin">Binance Coin</option>
                  <option value="monero">Monero</option>
                  <option value="chainlink">Chainlink</option>
                </Field>
              </div>

              <div>
                <label className="crypto-label"><strong>Filename</strong></label>
                <Field
                  className="form-control"
                  id="filename-input"
                  name="filename"
                  placeholder="Use default"
                  type="text"
                  component="input"
                  onFocus={(e) => e.preventDefault()}
                  onBlur={(e) => e.preventDefault()}
                  spellCheck="false"
                  autoComplete="off"
                />
              </div>
            </div>

            <button className="btn btn-primary btn-block" id="submit-crypto" type="submit" disabled={this.props.pristine || this.props.submitting}>
              <span id="loading-crypto" />
              &nbsp;Download Data
            </button>
          </form>

          <textarea id="crypto-textbox" rows="15" spellCheck="false" readOnly />
          <div id="csv-footer">
            <div id="csv-status" />
            <p id="download-status">{this.props.crypto}</p>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    crypto: state.crypto
  };
}

export default reduxForm({ form: 'CryptoForm' })(
  connect(mapStateToProps, { scrapeData })(CryptoData)
);
