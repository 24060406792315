// import { saveAs } from 'file-saver';
import { WEB_SCRAPE } from '../actions';

export default function crytpoReducer(state = [], action) {
  switch (action.type) {
    case WEB_SCRAPE: {
      document.getElementById("submit-crypto").disabled = false;
      document.getElementById("loading-crypto").className = "";

      return ("⬇️ CSV file downloaded");
    }
    default: {
      return state;
    }
  }
}
