import React from 'react';

const AboutUs = () => {
  return (
    <div className="text-wrapper">
      <div className="AboutMe-wrapper">
        <h4>About</h4>
        <p align="left">
        We are professional foreign exchange spot and derivatives traders with over 20
        years experience and also full-stack software engineers.
        </p>
        <p align="left">
        The examples shown in this web app are just a demonstration of the types of machine learning
        and data mining techniques that can be incorporated into your trading systems.  They form the basis
        of our own systems.  All can be easily built with GUI (graphic user interfaces) running locally.
        </p>
        <p align="left">
        Feel free to contact us for more information on consultation services and how we can help you.
        </p>
        <strong> FX Trading</strong>
        <ul>
          <li>Spot</li>
          <li>Forwards</li>
          <li>Futures</li>
          <li>Non-Deliverable Forwards</li>
          <li>Options</li>
          <li>Precious metals</li>
        </ul>
        <strong>Front-end</strong>
        <ul>
          <li>HTML</li>
          <li>CSS</li>
          <li>Javascript</li>
          <li>React/Redux</li>
        </ul>
        <strong>Back-end</strong>
        <ul>
          <li>Node.js</li>
          <li>Ruby/Ruby on Rails</li>
          <li>Python/Flask</li>
          <li>SQL Database</li>
          <li>RESTful api</li>
          <li>Websockets (SocketIO)</li>
        </ul>
        <strong>Algo Trading</strong>
        <ul>
          <li>LSTM Machine Learning</li>
          <li>Order submissions</li>
          <li>Automated Trading</li>
          <li>Strategy backtesting / development</li>
        </ul>
        <strong>Blockchain</strong>
        <ul>
          <li>Solidity</li>
        </ul>
        <br />
        <hr />
        <div className="icon-footer">
          {/*
            <a href="http://linkedin.com/in/chanleo1" target="_blank">
              <i className="fa fa-linkedin fa-2x" />
            </a>
            <a href="https://github.com/BluePelagos" target="_blank">
              <i className="fa fa-github-square fa-2x" />
            </a>
          <a href="mailto:info@tensr-capital.com" target="_blank">
            <i className="fa fa-envelope-square fa-2x" />
          </a>
          */}
        </div>
      </div>
    </div>

  );
};


export default AboutUs;
