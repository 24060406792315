
import { MODEL_UPLOAD } from '../actions';

export default function modelReducer(state = [], action) {
  switch (action.type) {
    case MODEL_UPLOAD: {
      document.getElementById('predict-textbox').value += 'Model config files uploaded!\n';
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
