import { saveAs } from 'file-saver';

export const NEW_PREDICTION = 'NEW_PREDICTION';
export const NEW_TWEET = 'NEW_TWEET';
export const WEB_SCRAPE = 'WEB_SCRAPE';
export const MODEL_UPLOAD = 'MODEL_UPLOAD';
export const MODEL_DOWNLOAD = 'MODEL_DOWNLOAD';


export function modelFileUpload(data) {
  // sending file by fetch API is the easiest way to transfer file objects
  // checks if upload file array exists
  if (!data.modelfiles) {
    return {
      type: 'MODEL_UPLOAD',
      payload: "No files to upload"
    };
  }

  const formData = new FormData();
  for (let i = 0; i < data.modelfiles.length; i += 1) {
    // loop through and append each file in the array
    formData.append('modelfiles[]', data.modelfiles[i]);
    console.log(data.modelfiles[i]);
  }

  const promise = fetch('https://tensr-trading-api.herokuapp.com/uploadModel', {
  // use below ommitted line for local development testing only
  // const promise = fetch('http://127.0.0.1:5000/uploadModel', {
    method: 'POST',
    body: formData,
  })
    .then(response => response.json());

  return {
    type: 'MODEL_UPLOAD',
    payload: promise
  };
}


export function downloadModel () {
  const promise = fetch('https://tensr-trading-api.herokuapp.com/downloadModel', {
  // const promise = fetch('http://127.0.0.1:5000/downloadModel', {
    method: 'POST'
  })
    .then(response => response.blob())
    .then(data => {
      // file response converted to blob object
      const fileBlob = data;
      // Use filereader api promise to read blob object response from fetch
      const r = new FileReader();
      r.onload = function fileReader() {
        saveAs(fileBlob, `model_config.zip`);
      };
      r.readAsText(fileBlob);
    });

  return {
    type: 'MODEL_DOWNLOAD',
    payload: promise
  };
}


// Below omitted code is for using RESTful api instead of socketIO
// ***************************************************************************

// export function predictValue(data) {
//   // extract redux form values and append to Formdata multipart data object
//   const formData = new FormData();
//   formData.append('model', data.model);
//   formData.append('rolling_window', data.rolling_window);
//   formData.append('neurons', data.neurons);
//   formData.append('epochs', data.epochs);
//   formData.append('batch', data.batch);
//   formData.append('upload', data.upload);
//   formData.append('file', data.file[0]);

//   const promise = fetch('http://127.0.0.1:5000/predict', {
//     method: 'POST',
//     body: formData,
//   })
//     .then(response => response.json());

//   return {
//     type: 'NEW_PREDICTION',
//     payload: "green-dot"
//   };
// }

// ***************************************************************************


// Sending file by SocketIO more probematic but have kept to illustrate process
export function predictValue(data) {
  const obj = {
    model: data.model,
    rolling_window: data.rolling_window,
    neurons: data.neurons,
    layers: data.layers,
    batch: data.batch,
    epochs: data.epochs,
    learning_rate: data.learning_rate,
    use_model: data.use_model,
    // file is sent as a b'' literal string to server.py
    file: data.file[0]
  };

  // Opens websocket on triggering the action creator
  $(document).ready(() => {
    // const socket = io.connect('http://127.0.0.1:5000');
    const socket = io.connect('https://tensr-trading-api.herokuapp.com');
    socket.on('message', (msg) => {
      // event listener for kill method for back end python script
      document.getElementById("kill-button").onclick = () => {
        if (socket.connected === true) {
          // close connection
          socket.send('kill connection');
          socket.disconnect();
          // renable submit button
          document.getElementById('submit-button').disabled = false;
          document.getElementById("predict-textbox").value += 'Connection stopping...\n';
          document.getElementById("file-status").className = "red-dot";
          document.getElementById("loading-file").className = "";
        }
      };
      if (msg !== "EOF") {
        // set textarea autoscroll and message refresh
        document.getElementById("predict-textbox").scrollTop = document.getElementById("predict-textbox").scrollHeight;
        document.getElementById("predict-textbox").value += `${msg}\n`;
      } else {
        // renable submit button
        document.getElementById('submit-button').disabled = false;
        document.getElementById("loading-file").className = "";
        document.getElementById("file-status").className = "red-dot";
        // close connection
        // socket.send('Connection stopped');
        socket.disconnect();
      }
    });
    // This must be at end of the function
    socket.emit('dataObject', obj);
  });

  return {
    type: 'NEW_PREDICTION',
    payload: "green-dot"
  };
}


export function anaylseTweet(tweetData) {
  document.getElementById("tweet-status").className = "green-dot";

  const formData = new FormData();
  formData.append('query', tweetData.query);
  formData.append('count', tweetData.count);

  // const promise = fetch('http://127.0.0.1:5000/tweet', {
  const promise = fetch('https://tensr-trading-api.herokuapp.com/tweet', {
    method: 'POST',
    body: formData,
  })
    .then(response => response.json());

  return {
    type: 'NEW_TWEET',
    payload: promise
  };
}


export function scrapeData(data) {
  try {
    document.getElementById("csv-status").className = "green-dot";
    const formData = new FormData();
    formData.append('coin', data.coin);
    const csvFile = data.filename;
    const coin = data.coin;
    const todayDate = new Date().toISOString().slice(0, 10);

    // const promise = fetch('http://127.0.0.1:5000/webScrape', {
    const promise = fetch('https://tensr-trading-api.herokuapp.com/webScrape', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.blob())
      .then(data => {
        // csv response converted to blob object
        const newBlob = data;
        // Use filereader api promise to read blob object response from fetch
        const reader = new FileReader();
        // Use filesaver.js to save blob as CSV file
        // save using custom filename
        reader.onload = function fileReader() {
          const resultData = reader.result;
          // check if form field for filename exists or not
          if (typeof (csvFile) === 'undefined') {
            saveAs(newBlob, `${coin}${todayDate}.csv`);
          } else {
            saveAs(newBlob, `${csvFile}.csv`);
          }
          // Display reader output to the textarea
          document.getElementById("crypto-textbox").value = resultData;
        };
        reader.readAsText(newBlob);
      });

    return {
      type: 'WEB_SCRAPE',
      payload: promise
    };
  } catch (err) {
    document.getElementById('crypto-textbox').value = "Error scraping data, please try again!";
    document.getElementById("submit-crypto").disabled = false;
    document.getElementById("loading-crypto").className = "";
    document.getElementById("csv-status").className = "red-dot";
  }
}
