
import { MODEL_DOWNLOAD } from '../actions';

export default function downloadReducer(state = [], action) {
  switch (action.type) {
    case MODEL_DOWNLOAD: {
      return ("⬇️ Model config files downloaded");
    }
    default: {
      return state;
    }
  }
}
