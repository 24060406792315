import React from 'react';

const Details = () => {
  return (
    <div className="text-wrapper">
      <div className="home-page-wrapper">
        <h4>Machine Learning Application</h4>
        <p align="justify">
        The front end has been built using <strong>React / Redux-Form / React-Router</strong>.
        The back end runs python scripts via <strong>flask server</strong> using RESTful APIs and SocketIO.
        This shows what can be done using a combination of these powerful
        frameworks.  Everything is done in memory using python without the need to use a database
        in the back end.
        </p>
        <h5>Neural Networks</h5>
        <p align="justify">
        Three different types of neural network models can be selected and the
        rolling lookback period can be chosen for the time series data.
        The models are coded using the Google <strong>Tensor Flow</strong> module in python.
        </p>
        <p align="justify">
        The data must be uploaded as a CSV file of open, high, low, close data.
        The model calibration may take a few minutes to run depending on the parameters.  It will
        return a prediction for the next period closing price.  The status of the process is displayed
        in real time using Flask-SocketIO which opens a permanent connection between server and client.
        </p>
        <p align="justify">
        You can choose model calibration mode which will train and save a model's parameters.  Alternatively,
        you can upload a previously saved model's optimised weights and settings and run it with new data.
        </p>
        <h5>NLP Sentiment Analysis</h5>
        <p align="justify">
        This is an example of <strong>natural language processing</strong> to
        anaylse the sentiment of related tweets on twitter.  It uses a bayes classifier
        to classify word content against a pre-calibrated tweet database.  Provide some text to
        search for and it will return the sentiment as:-
        positive, negative, or neutral.
        </p>
        <h5>Cryptocurrency Data Webscraper</h5>
        <p align="justify">
        Using the <strong>Beautiful Soup</strong> webscraping module
        in python, we scrape open, high, low, close data from a source on the internet.
        The complete data for the selected coin can be downloaded into a CSV
        file or just copied from the text box.
        </p>
      </div>
    </div>
  );
};

export default Details;
