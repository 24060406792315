import React from 'react';


// listen to the resize event to handle mobile
window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


const LandingPage = () => {
  return (
    <div>

      <div>
        <div id="main-picture" />
        {/* <video playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">
          <source src="https://storage.googleapis.com/coverr-main/mp4/Mt_Baker.mp4" type="video/mp4" />
        </video> */}
        <div className="landing-page-header">
          <span id="logo-title1">Where </span>
          <span id="logo-title2">technology </span>
          <span id="logo-title1">meets </span>
          <span id="logo-title2">finance</span>
        </div>
      </div>

      <div id="content-banner">
        <span id="heading-one">TENSR</span>
        <span id="heading-two"> CAPITAL</span>
        <div id="mission-statement">
          <h4>Producing absolute returns through quantitative trading</h4>
        </div>
      </div>

      <div id="content-container">

        <div id="card-container" className="row">

          <div className="card-item col-12 col-sm-6 col-lg-3">
            <div className="inner-card">
              <i className="fa fa-database fa-2x content-icon" />
              <h5 className="card-text">Data</h5>
              <p>We collect, clean, and normalize data from reliable sources, including alternative data sources for testing</p>
            </div>
          </div>

          <div className="card-item col-12 col-sm-6 col-lg-3">
            <div className="inner-card">
              <i className="fa fa-wrench fa-2x content-icon" />
              <h5 className="card-text">Build</h5>
              <p>We build a diversified portfolio through a combination of many trading models based on extensive statistical testing with machine learning</p>
            </div>
          </div>

          <div className="card-item col-12 col-sm-6 col-lg-3">
            <div className="inner-card">
              <i className="fa fa-cogs fa-2x content-icon" />
              <h5 className="card-text">Backtest</h5>
              <p>Our models are backtested on historical data across various time frames and markets for robustness</p>
            </div>
          </div>

          <div className="card-item col-12 col-sm-6 col-lg-3">
            <div className="inner-card">
              <i className="fa fa-bolt fa-2x content-icon" />
              <h5 className="card-text">Live Trading</h5>
              <p>Our system trades automatically around the clock in currencies, equities and commodities</p>
            </div>
          </div>

        </div>
      </div>

      <div id="landing-footer">
        © 2019 Tensr Capital, Ltd. All Rights Reserved.
      </div>

    </div>
  );
};

export default LandingPage;
