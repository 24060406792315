// external modules
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import reduxPromise from 'redux-promise';
import logger from 'redux-logger';
import { reducer as formReducer } from 'redux-form';

// internal modules
import App from './components/app';
import '../assets/stylesheets/application.scss';

// State and reducers
import predictionReducer from './reducers/prediction_reducer';
import tweetReducer from './reducers/tweet_reducer';
import cryptoReducer from './reducers/crypto_reducer';
import modelReducer from './reducers/model_reducer';
import downloadReducer from './reducers/download_reducer';

const initialState = {
  prediction: [],
  tweet: [],
  crypto: [],
  model: [],
  download: []
};

const reducers = combineReducers({
  form: formReducer,
  prediction: predictionReducer,
  tweet: tweetReducer,
  crypto: cryptoReducer,
  model: modelReducer,
  download: downloadReducer
});

// Middlewares
const middlewares = applyMiddleware(reduxPromise, logger);
const store = createStore(reducers, initialState, middlewares);

// render an instance of the component in the DOM
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
