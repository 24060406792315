import React from 'react';


const Portfolio = () => {
  return (
    <div id="portfolio-container">
      <div className="header-container">
        <span id="contact-header">PORTFOLIO</span>
      </div>
      <div id="portfolio-text">
        <h5 id="crypto-header">Crypto Balance</h5>
        <h5 id="crypto-text" align="justify">CryptoBalance is a trading tool to help you to automate your crypto currency trading needs.
        You can choose to follow an index and our system will automatically make any trades needed to rebalance to the allocations you choose
        </h5>
        <div id="crypto-balance-button">
          <a href="https://crypto-balance-hk.herokuapp.com" target="_blank">
            <button className="btn btn-info">Crypto Balance</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
