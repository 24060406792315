import React from 'react';
import NewNavBar from './new_nav_bar';


const App = () => {
  return (
    <div>
      <NewNavBar />
    </div>
  );
};

export default App;
