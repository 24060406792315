import React from 'react';
import {
  NavLink, BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import { createHistory as history } from 'history';
import Details from './details';
import PredictNew from '../containers/predict_new';
import TweetNew from '../containers/tweet_new';
import CryptoData from '../containers/crypto_data';
import AboutUs from './about_us';
// import NavBar from './nav_bar';


const MainApp = (props) => {
  console.log(props);
  return (
    <Router history={history}>

      <div className="mlapp-container">
        <div className="main-container">
          <div className="side-bar">
            <NavLink className="menu-tab" activeClassName="active" to="/machine_learning" exact>The App</NavLink>
            <NavLink className="menu-tab" activeClassName="active" to="/machine_learning/NeuralNetwork">Neural Network</NavLink>
            <NavLink className="menu-tab" activeClassName="active" to="/machine_learning/TwitterSentiment">Twitter Sentiment</NavLink>
            <NavLink className="menu-tab" activeClassName="active" to="/machine_learning/CryptoData">Crypto Data</NavLink>
            <NavLink className="menu-tab" activeClassName="active" to="/machine_learning/AboutMe">About</NavLink>
          </div>
          <div className="dashboard">
            <Switch>
              <Route exact path="/machine_learning" component={Details} />
              <Route path="/machine_learning/NeuralNetwork" component={PredictNew} />
              <Route path="/machine_learning/TwitterSentiment" component={TweetNew} />
              <Route path="/machine_learning/CryptoData" component={CryptoData} />
              <Route path="/machine_learning/AboutMe" component={AboutUs} />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default MainApp;
