
import { NEW_TWEET } from '../actions';

export default function tweetReducer(state = [], action) {
  switch (action.type) {
    case NEW_TWEET: {
      // if empty query input then make sure submit button is off
      if (document.getElementById("query").value === "") {
        document.getElementById("submit-tweet").disabled = true;
      }
      // Cancel spinner if no tweets returned
      if (action.payload === "No tweets found") {
        document.getElementById("loading-tweet").className = "";
        return ('❌ No tweets found! ');
      }
      // Otherwise enable submit button and and cancel spinner
      document.getElementById("submit-tweet").disabled = false;
      document.getElementById("loading-tweet").className = "";

      // should this be moved back to the action creator as a chained response?
      // formating response footer string
      const positive = `👍${action.payload.positivepct[0].toFixed(0)}%\u00A0\u00A0`;
      const negative = `👎${action.payload.negativepct[0].toFixed(0)}%\u00A0\u00A0`;
      const neutral = `😐${action.payload.neutralpct[0].toFixed(0)}%`;

      // formating textarea repsonse string
      const total = `\n✅ TOTAL TWEETS: ${action.payload.total[0]}\n`;
      const underline = "*****************************\n";

      let negTweetString = "\n\n⚛NEGATIVE TWEETS:\n\n";
      action.payload.negative.forEach((negTweetItem) => {
        negTweetString += `${negTweetItem.text}\n\n`;
      });

      let posTweetString = "\n⚛POSITIVE TWEETS:\n\n";
      action.payload.positive.forEach((posTweetItem) => {
        posTweetString += `${posTweetItem.text}\n\n`;
      });

      let neuTweetString = "\n\n⚛NEUTRAL TWEETS:\n\n";
      action.payload.neutral.forEach((neuTweetItem) => {
        neuTweetString += `${neuTweetItem.text}\n\n`;
      });

      // Display textarea content
      const tweetString = total + underline + posTweetString + negTweetString + neuTweetString;
      document.getElementById("tweet-textbox").value = tweetString;

      const resultString = positive + negative + neutral;

      return (
        // return final footer string as new state
        resultString
      );
    }

    default: {
      // ensures state is empty if no content response from fetch api
      if (action.payload !== "") {
        console.log("clearing state");
        return "";
      }
      console.log("same intial state");
      return state;
    }
  }
}
