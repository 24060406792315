import React from 'react';
import {
  NavLink, BrowserRouter as Router, Route, Switch
} from 'react-router-dom';
import { createHistory as history } from 'history';
import Disclaimer from './disclaimer';
import LandingPage from './landing_page';
import MlApp from './ml_app';
import Contact from './contact';
import Portfolio from './portfolio';


const NewNavBar = () => {
  return (
    <Router history={history}>
      <div className="navbar navbar-expand-sm navbar-light navbar-lewagon fixed-top" data-toggle="collapse" data-target=".navbar-collapse.show">
        <NavLink id="navbar-title" className="nav-link" activeClassName="active" to="/">
          <span id="title-1">TENSR </span>
          <span id="title-2">CAPITAL</span>
        </NavLink>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink className="nav-link" activeClassName="active" to="/" exact>About</NavLink>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink className="nav-link" activeClassName="active" to="/machine_learning">Tools</NavLink>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink className="nav-link" activeClassName="active" to="/crypto_management">Portfolio</NavLink>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink className="nav-link" activeClassName="active" to="/contact">Contact</NavLink>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink className="nav-link" activeClassName="active" to="/disclaimer">Disclaimer</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="content">
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/machine_learning" component={MlApp} />
          <Route path="/contact" component={Contact} />
          <Route path="/crypto_management" component={Portfolio} />
          <Route path="/disclaimer" component={Disclaimer} />
        </Switch>
      </div>
    </Router>
  );
};

export default NewNavBar;
