import React from 'react';


const Contact = () => {
  return (
    <div id="contact-container">
      <div className="header-container">
        <span id="contact-header">CONTACT</span>
        <a id="contact-email" href="mailto:info@tensr-capital.com" target="_blank">
          <h5 id="email-add">info@tensr-capital.com</h5>
        </a>
      </div>
    </div>
  );
};

export default Contact;
